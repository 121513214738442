import { TouchEvent, useEffect, useRef } from 'react';

import { useContextMediaQuery } from '@core/context';
import { useCarouselSteps } from '@core/hook';
import { CollectionsBannersResponse } from '@core/type/api';

import { SCROLL_EXTRA_DELTA_X, useCarouselSwipe } from './use-carouse-swipe';

const AUTOPLAY_INTERVAL = 6000; // 6 sec

export const useCarouselBanners = (bannersProps: CollectionsBannersResponse) => {
  const startTouchXRef = useRef<number>(null);
  const endTouchXRef = useRef<number>(null);
  const startTouchYRef = useRef<number>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentStepRef = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout>(null);

  const { mediaQuery } = useContextMediaQuery();

  const {
    currentStep,
    onPrevious,
    onNext,
    onClick: setSlide,
    ...restCarouselProps
  } = useCarouselSteps();

  useCarouselSwipe(startTouchXRef, endTouchXRef, startTouchYRef);

  currentStepRef.current = currentStep;
  const totalSteps = bannersProps?.items?.length;

  const getTotalSlidesWidthBeforeCurrent = () => {
    const slideWidth = containerRef?.current?.offsetWidth;

    return currentStep * slideWidth;
  };

  const startAutoplay = () => {
    stopAutoplay();

    intervalRef.current = setInterval(handleNext, AUTOPLAY_INTERVAL);
  };

  const stopAutoplay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (totalSteps) {
      startAutoplay();
    }

    return () => stopAutoplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSteps]);

  useEffect(() => {
    if (carouselRef?.current?.style && containerRef?.current) {
      carouselRef.current.style.transform = `translateX(-${getTotalSlidesWidthBeforeCurrent()}px)`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, containerRef?.current?.offsetWidth]);

  useEffect(() => {
    setSlide(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQuery]);

  const handleTouchStart = ({ touches }: TouchEvent) => {
    stopAutoplay();

    if (totalSteps > 1) {
      startTouchXRef.current = touches[0]?.clientX;
      startTouchYRef.current = touches[0]?.clientY;
    }
  };

  const handleTouchMove = ({ touches }: TouchEvent) => {
    endTouchXRef.current = touches[0]?.clientX;

    if (startTouchXRef.current === null || carouselRef.current === null) {
      return;
    }

    const deltaX = touches[0]?.clientX - startTouchXRef.current;
    const deltaY = touches[0]?.clientY - startTouchYRef.current;

    if (Math.abs(deltaX) + SCROLL_EXTRA_DELTA_X > Math.abs(deltaY)) {
      carouselRef.current.style.transform = `translateX(calc(-${getTotalSlidesWidthBeforeCurrent()}px + ${deltaX}px))`;
    } else {
      startTouchXRef.current = null;
      startTouchYRef.current = null;
      carouselRef.current.style.transform = `translateX(-${getTotalSlidesWidthBeforeCurrent()}px)`;
    }
  };

  const handleTouchEnd = () => {
    if (startTouchXRef.current === null || endTouchXRef.current === null) {
      return;
    }

    const deltaX = startTouchXRef.current - endTouchXRef.current;

    if (deltaX > 0) {
      handleNext();
    } else if (deltaX < 0) {
      handlePrevious();
    }

    if (currentStep === 0) {
      carouselRef.current.style.transform = 'translateX(0px)';
    } else if (currentStep === totalSteps - 1) {
      carouselRef.current.style.transform = `translateX(-${getTotalSlidesWidthBeforeCurrent()}px)`;
    }

    endTouchXRef.current = null;
    startTouchXRef.current = null;
    startTouchYRef.current = null;

    startAutoplay();
  };

  const handlePrevious = () => {
    if (currentStep === 0) {
      return setSlide(totalSteps - 1);
    }

    onPrevious();
  };

  const handleNext = () => {
    if (currentStepRef.current === totalSteps - 1) {
      return setSlide(0);
    }

    onNext();
  };

  return {
    ref: carouselRef,
    containerRef,
    currentStep,
    bannersProps: bannersProps,
    totalSteps,
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
    handlePrevious,
    handleNext,
    setSlide,
    startAutoplay,
    stopAutoplay,
    ...restCarouselProps,
  };
};
